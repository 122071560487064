import {
  type CTAItem,
  type ROUTE_API_ERROR_FACT,
  primaryReload,
  secondaryLogin,
  tertiaryClear,
} from '@iheartradio/web.remix-shared';

export const ROUTE_API_ERROR: ROUTE_API_ERROR_FACT = ({ root, pathname }) => ({
  401: {
    TITLE: `Your Session Timed Out`,
    DESCRIPTION: `Please log in or reload the page to continue listening.`,
    ICON: 'error-outline',
    CTA: (({ root, pathname }) => {
      const default401: CTAItem[] = [
        primaryReload,
        secondaryLogin,
        tertiaryClear,
      ];

      if (root) {
        switch (pathname) {
          default: {
            return [...default401];
          }
        }
      } else {
        switch (pathname) {
          default: {
            return [...default401];
          }
        }
      }
    })({ root, pathname }),
  },
  403: {
    TITLE: `Your Session Timed Out`,
    DESCRIPTION: `Please reload the page to continue listening.`,
    ICON: 'error-outline',
    CTA: (({ root, pathname }) => {
      const default403: CTAItem[] = [primaryReload, tertiaryClear];

      if (root) {
        switch (pathname) {
          default: {
            return [...default403];
          }
        }
      } else {
        switch (pathname) {
          default: {
            return [...default403];
          }
        }
      }
    })({ root, pathname }),
  },
  404: {
    TITLE: `Page Not Found`,
    DESCRIPTION: `Sorry, we can't find the page you're looking for.`,
    ICON: 'error-outline',
    CTA: (({ root, pathname }) => {
      const default404: CTAItem[] = [];

      if (root) {
        switch (pathname) {
          default: {
            return [...default404];
          }
        }
      } else {
        switch (pathname) {
          default: {
            return [...default404];
          }
        }
      }
    })({ root, pathname }),
  },
  408: {
    TITLE: `Request Timeout`,
    DESCRIPTION: `Sorry, this is taking longer than expected. Try reloading the page or check back later.`,
    ICON: 'error-outline',
    CTA: (({ root, pathname }) => {
      const default408: CTAItem[] = [primaryReload, tertiaryClear];

      if (root) {
        switch (pathname) {
          default: {
            return [...default408];
          }
        }
      } else {
        switch (pathname) {
          default: {
            return [...default408];
          }
        }
      }
    })({ root, pathname }),
  },
  500: {
    TITLE: `Internal Server Error`,
    DESCRIPTION: `Sorry, we couldn’t complete your request. Please check back later.`,
    ICON: 'error-outline',
    CTA: (({ root, pathname }) => {
      const default500: CTAItem[] = [primaryReload, tertiaryClear];

      if (root) {
        switch (pathname) {
          default: {
            return [...default500];
          }
        }
      } else {
        switch (pathname) {
          default: {
            return [...default500];
          }
        }
      }
    })({ root, pathname }),
  },
  503: {
    TITLE: `Service Not Available`,
    DESCRIPTION: `Sorry, we couldn't complete your request. Try reloading the page or check back later.`,
    ICON: 'error-outline',
    CTA: (({ root, pathname }) => {
      const default503: CTAItem[] = [primaryReload, tertiaryClear];

      if (root) {
        switch (pathname) {
          default: {
            return [...default503];
          }
        }
      } else {
        switch (pathname) {
          default: {
            return [...default503];
          }
        }
      }
    })({ root, pathname }),
  },
  504: {
    TITLE: `Request Timeout`,
    DESCRIPTION: `Sorry, this is taking longer than expected. Try reloading the page or check back later.`,
    ICON: 'error-outline',
    CTA: (({ root, pathname }) => {
      const default504: CTAItem[] = [primaryReload, tertiaryClear];

      if (root) {
        switch (pathname) {
          default: {
            return [...default504];
          }
        }
      } else {
        switch (pathname) {
          default: {
            return [...default504];
          }
        }
      }
    })({ root, pathname }),
  },
});
